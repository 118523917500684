<template>
	<v-menu class="MultiDatePicker"
		v-model="menu"
		width="auto"
		:disabled="disabled"
		location="bottom"
		:close-on-content-click="false"
		transition="none"
	>
		<!-- TODO: use parent activator instead to make layout control easier? -->
		<template v-slot:activator="{ props }">
			<div v-bind="props" class="chips-selector" ref="selector" :data-cy="dataCy"
				:class="{ menuOpen: menu }"
				style="flex: 1; min-width: 130px;"
			>
				<!--
				<v-chip v-for="date in modelValue" :key="date" @click="go(date)" style="cursor: pointer;">
					{{ date }}
					<v-icon @click.stop="remove(date)">mdi-close</v-icon>
				</v-chip>
				-->
				<div v-for="date, d in modelValue" :key="date" class="date" @click="go(date)"
					:style="d >= visibleCount - 1 ? 'display: none;' : ''"
				>
					<template v-if="date.match(/^\d/)">
						<div class="month">{{ formatMonth(date) }}</div>
						<div class="year">{{ formatYear(date) }}</div>
						<div class="dom">{{ formatDom(date) }}</div>  
					</template>
					<template v-else>
						<div class="weekdayInfo">{{ $t('text.all') }}</div>
						<div class="year"></div>
						<div class="dom">{{ formatDow(date) }}</div>  
					</template>
					<v-icon @click.stop="remove(date)">mdi-close</v-icon>
				</div>
				<div class="date" v-if="modelValue.length >= visibleCount" style="font-size: inherit;">
					+
					{{ modelValue.length - visibleCount + 1 }}
				</div>
				<span v-if="!modelValue.length" style="color: #999;">{{ $t('text.selectDays') }}</span>
				<v-icon class="accessor">mdi-calendar</v-icon>
			</div>
		</template>

		<div class="picker-wrapper">
			<ColorCalendar
				v-if="menu"
				class="calendar"
				ref="calendar"
				:calendar="calendar"
				:dates="dates"
				:profiles="profiles"
				:paintingProfile="profile"
				:focusStart="focusStart"
				:focusEnd="focusEnd"
				@focusRangeChanged="focusStart = $event.focusStart; focusEnd = $event.focusEnd"
				@painted="paint($event.profile, $event.days)"
			>
				<!-- TODO: do we want hover support on this calendar?
				<template #day-hover="{ day }" v-if="$slots[ 'day-hover' ]">
				</template>
				-->
			</ColorCalendar>
			<div class="weekdays">
				<div id="btnEditWeekday" v-for="weekday in daysOfWeek" :key="weekday"
					class="weekday"
					@click="toggleWeekday(weekday)"
					:class="{
						inactive: !containsWeekday(weekday)
					}"
				>
					<v-icon v-if="containsWeekday(weekday)" size="small" style="margin-left: -5px;">mdi-check</v-icon>
					{{ $t(`text.${ weekday.toLowerCase() }`) }}
				</div>
			</div>
		</div>
	</v-menu>
</template>

<script lang="ts">
import moment from 'moment'
import ColorCalendar from './ColorCalendar.vue'
import { calendarCalculatorMixin } from './ProfileColorCalendar.vue'

// ATT: this is currently built to work like ProfileColorCalendar, so it also uses
//      the same mixin and the same data structure for the profiles.
//      in future we may want to make it possible to use this with other calendar modes also.

export default {
	components: { ColorCalendar },
	mixins: [ calendarCalculatorMixin ],
	props: {
		modelValue: Array, // example: [ 2023-01-01, 2023-01-02, 'Monday', 'Tuesday' ]
		dataCy: String,
		disabled: Boolean,
		min: String,
		max: String,
		profile: Object,
		multiple: Boolean,
		profiles: Array,
		calcProfiles: Function,
	},
	data: () => ({
		menu: false,
		daysOfWeek: [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ],
		resizeListener: null,
		visibleCount: 0,
	}),
	methods: {
		formatDom(date: string) { if (date.match(/^\d/)) return moment(date).format('D') },
		formatMonth(date) {
			if (!date) return ''
			const locale = this.$vuetify.locale.current
			return Intl.DateTimeFormat(locale, { month: 'short' }).format(new Date(date))
			//return moment(date).format('MMM')
		},
		formatYear(date) { return moment(date).format('\'YY') },
		formatDow(date) { return this.$t(`text.${ date }`).substr(0, 2) },
		go(date) {
			// TODO: wait for calendar ref to be available instead of timeout
			window.setTimeout(() => {
				if (!this.$refs.calendar) return
				this.$refs.calendar.go(date)
			}, 100)
		},
		toggleWeekday(item) {
			if (this.modelValue.includes(item)) {
				this.remove(item)
				return
			}
			this.modelValue.push(item)
		},
		remove(item) {
			const i = this.modelValue.indexOf(item)
			if (i === -1) return
			this.modelValue.splice(i, 1)
		},
		containsWeekday(weekday) {
			return this.modelValue.includes(weekday)
		},
		paint(profile, days) {
			if (!days?.[0]?.date) return
			// TODO: first date does not exist -> remove mode
			const remove = this.modelValue.includes(days[0].date)
			for (const day of days) {
				if (remove) {
					this.remove(day.date)
					continue
				}
				if (this.modelValue.includes(day.date)) continue
				this.modelValue.push(day.date)
			}
		},
		calcVisible() {
			if (!this.$refs.selector) return
			const bounds = this.$refs.selector.getBoundingClientRect()
			if (bounds.width == 0) return
			this.visibleCount = Math.floor((bounds.width - 10 - 19) / 50)
		},
	},
	mounted() {
		this.resizeListener = window.addEventListener('resize', this.calcVisible)
		this.calcVisible()
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.resizeListener)
	},
}
</script>

<style scoped>
/* TODO: general field style instead of repeating this here */
.chips-selector { position: relative; max-height: 50px; display: flex; place-content: flex-start; flex-wrap: wrap; gap: 5px; background: white; width: auto; transition: max-height 0.3s; overflow-y: hidden; align-items: center; border: 1px solid #ddd; border-radius: 4px; padding: 5px; padding-right: 20px; }
.chips-selector.menuOpen { max-height: 135px; border-color: var(--col-selected); overflow-y: auto; }
.accessor { position: absolute; right: 5px; top: 10px; color: gray; }
.v-chip { border-radius: 4px; padding: 0px 10px; cursor: pointer; }
.picker-wrapper { display: flex; gap: 12px; box-shadow: 1px 3px 15px rgba(0,0,0,0.2); padding: 12px; border-radius: 4px; background: white; display: flex; gap: 10px; }
.weekdays { display: flex; gap: 12px; flex-direction: column; align-items: flex-start; }
.weekday { cursor: pointer; font-size: 10pt; padding: 1px 10px; border-radius: 6px; background: #ddd; border: 1px solid #ddd; }
.inactive { background: none; }

.date { display: inline-grid; background-color: #e0e0e0; grid-template-columns: auto auto; border-radius: 4px; padding: 2px 4px; width: 46px; font-family: sans-serif; text-align: center; font-size: 10pt; }
.date .weekdayInfo,
.date .year,
.date .month { font-size: x-small; font-family: monospace; }
.date .weekdayInfo { color: gray; text-align: left; font-family: inherit; }
.date .year { margin-left: -2px; color: gray; }
</style>