<template>
  <div :id="'translationActivator-' + uuid" class="translate-wrapper">
    <v-icon class="translate-icon">mdi-vector-curve</v-icon>
  </div>
  <v-menu :activator="'#translationActivator-' + uuid">
      <v-list>
        <v-list-item v-for="(option, index) in settings" :key="index" @click="$emit(option.id)">
          {{option.text}}
        </v-list-item>
      </v-list>
  </v-menu>
</template>

<script lang="ts">
import uniqueId from 'lodash/uniqueId'

export default {
  emits: ['settings', 'translate'],
  props: {
    settings: Array
  },
  beforeCreate() {
    this.uuid = uniqueId()
  }
}
</script>
