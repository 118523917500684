<template>
	<div class="DraggableDisclosureList">
		<div class="header" v-if="$slots.header">
			<slot name="header" />
		</div>
		<draggable v-model="model" @start="drag = true" @end="drag = false" handle=".handle">
			<div v-for="item in model" :key="item.id" class="item"
				:ref="'item-' + item.id"
				:class="{
					selected: selectedItem?.id == item.id,
				}"
				@mousedown="select(item)"
				:style="{
					display: itemVisible && itemVisible(item) ? 'block' : 'none',
				}"
			>
				<div class="item-header">
					<v-icon class="handle">mdi-drag-horizontal</v-icon>
					<v-icon v-if="expanded[ item.id ]" @click="collapse(item.id)">mdi-triangle-small-up</v-icon>
					<v-icon v-else @click="expand(item.id)">mdi-triangle-small-down</v-icon>
					<slot name="item-header" :item="item" />
					<v-icon @click="model.splice(model.indexOf(item), 1)">mdi-delete</v-icon>
				</div>
				<div class="item-body" v-if="expanded[ item.id ]">
					<slot name="item-body" :item="item" />
					<div class="hint" v-if="selectedItem?.id == item.id && $slots['selected-item-hint']">
						<slot name="selected-item-hint" :item="item" />
					</div>
				</div>
			</div>
		</draggable>
	</div>
</template>

<script lang="ts">
import { VueDraggableNext as draggable } from 'vue-draggable-next'

export default {
	components: { draggable: draggable as any },
	props: {
		// Array of Objects, each having an id property
		modelValue: Array,
		startExpanded: { type: [ Boolean, Function ], default: false },
		selectable: Boolean,
		itemVisible: Function,
	},
	data: () => ({
		expanded: {},
		drag: false,
		model: [],
		selectedItem: null,
	}),
	watch: {
		modelValue() { this.model = this.modelValue },
		model() { this.$emit('update:modelValue', this.model) },
	},
	methods: {
		expand(id) {
			this.expanded[id] = true
		},
		collapse(id) {
			this.expanded[id] = false
		},
		select(o) {
			if (this.selectedItem == o) return
			this.selectedItem = o
			this.$emit('select', o)
		},
		scrollTo(id) {
			this.$refs[ 'item-' + id ]?.[0]?.scrollIntoView?.({ behavior: 'smooth', block: 'center' })
		},
	},
	beforeMount() {
		this.model = this.modelValue
	},
	mounted() {
		if (this.model) {
			this.model.forEach((profile) => {
				let val = this.startExpanded
				if (typeof val === 'function') val = val(profile)
				this.expanded[profile.id] = val
			})
		}
	},
}
</script>

<style scoped>
.DraggableDisclosureList { --sel: lightskyblue; --focus: #295dc7; }
.header { padding-left: 70px; padding-right: 40px; padding-bottom: 10px; }
.item { background: white; }
.item.selected { outline: 2px solid var(--sel); }
.item-header { display: flex; align-items: center; gap: 5px; padding: 10px; background: #00000004; border-bottom: 1px solid #ddd; }
.item-body { position: relative; }
</style>