<template>
	<Disclosure
		:title="$t('text.seasonalPricing')"
		:error="sectionMessage.error"
		:warning="sectionMessage.warning"
		:message="sectionMessage.message"
		data-cy="ticketInfo"
		ref="SECTION_seasonalPricing"
		v-if="modelValue"
	>
		{{ product?.fields?.ticket?.de?.fields?.ticketOptions?.de?.[0] }}
		<template #header-accessory>
			<div style="flex: 1;" />
			<ProFeatureTag />
		</template>
		<div v-if="!ticketsDefined">
			{{ $t('text.seasonalPricingTicketsMissing') }}
		</div>
		<div v-else>
			<!-- TODO: show editable price matrix for default prices?
						probably not easily possible to make reactive with Ticket.. -->
			<Field :title="$t('text.seasonalPricing')" :infoText="$t('text.seasonalPricing_info')" />
			<PriceMatrixCalendar v-model="modelValue.de" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" />
			<Field :title="$t('text.seasonalPricingExceptions')" :infoText="$t('text.seasonalPricingExceptions_info')" />
			<PriceMatrixCalendar v-model="modelValue.de" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" mode="exception" />
		</div>
	</Disclosure>
</template>

<script lang="ts">
import Common from '../../../mixins/Common.vue'
import Disclosure from '../../../components/common/Disclosure.vue'
import PriceMatrixCalendar from '../packageDesigner/PriceMatrixCalendar.vue'
import ProFeatureTag from '../packageDesigner/ProFeatureTag.vue'
import Field from '../../../components/fields/Field.vue'
import { calcBasePrices } from '../../../../../shared/seasonal-prices'
import eventBus from '@/utils/eventBus.js'

export default {
	components: { Disclosure, PriceMatrixCalendar, ProFeatureTag, Field },
	mixins: [ Common ],
	props: {
		product: Object,
		serviceProvider: Object,
		updateModel: Boolean,
		productIsExternal: Boolean,
		modelValue: Object, // { de: PricingTimespan[] }
	},
	data: () => ({
		sectionMessage: {
			error: false,
			message: ''
		},
		profiles: [
			// prices: { adult_1: 0, child_1: 0, senior_1: 0, adult_2: 0, child_2: 0, senior_2: 0 }
			//{ id: 0, color: null, name: 'default', weekdays: [], prices: {}, isRemove: true },
			{ id: 1, color: '#aae4fa', name: 'summer', weekdays: [], prices: [], start: '2024-10-01', end: '2024-10-31' },
			{ id: 2, color: '#cbedb6', name: 'winter', weekdays: [], prices: [], start: null, end: null },
			{ id: 3, color: '#ffe6aa', name: 'holiday', weekdays: [], prices: [], start: null, end: null },
		],
		hasTicket: false,
	}),
	computed: {
		// merge ticketTypes and ticketCategories from client and serviceProvider
		// this is essentially what the user can select from
		// gives the complete objects (with .fields)
		allTicketTypes() {
			return [
				...this.$store.state.selectedServiceProvider.fields.ticketTypes.de,
				...this.$store.state.selectedClient.fields.ticketTypes.de,
			]
		},
		allTicketCategories() {
			return [
				...this.$store.state.selectedServiceProvider.fields.reductions.de,
				...this.$store.state.selectedClient.fields.reductions.de,
			]
		},
		// ticket variations of the product (only contains links to the actual ticketType and reduction)
		ticketOptions() {
			const ticket = this.product?.fields?.ticket?.de
			return ticket?.fields?.ticketOptions?.de ?? []
		},
		// full objects that are linked in the ticketOptions
		ticketTypes() {
			const ids = this.ticketOptions.map((option: any) => option.fields?.ticketType?.de?.sys?.id)
			return this.allTicketTypes.filter((o: any) => ids.includes(o.sys.id))
		},
		ticketCategories() {
			const ids = this.ticketOptions.map((option: any) => option.fields?.reduction?.de?.sys?.id)
			return this.allTicketCategories.filter((o: any) => ids.includes(o.sys.id))
		},
		ticketsDefined() {
			return this.ticketTypes.length > 0 && this.ticketCategories.length > 0
		},
		basePrices() {
			return calcBasePrices(this.ticketOptions)
		},
	},
	watch: {
		hasTicket: {
			handler: function (val) {
				if (!val) this.setSectionWarning(this.sectionMessage, this.$t('text.ticketRequiredWarning'))
				else this.resetSectionWarning(this.sectionMessage)
			},
			immediate: true,
		},
	},
	methods: {
		/*updateTicketWarning() {
			console.log('update ticket warning')
			const to0 = this.product.fields.ticket?.de?.fields?.ticketOptions?.de?.[0]
			const hasTicket = to0 && to0.fields?.ticketType?.de && to0.fields?.reduction?.de && to0.fields?.price?.de
			if (!hasTicket) this.setSectionWarning(this.sectionMessage, this.$t('text.ticketRequiredWarning'))
			else this.resetSectionWarning(this.sectionMessage)
		},*/
	},
	mounted() {
		// TODO: copied this mechanism from TicketPersonalisations
		//       would prefer a more elegant solution (see updateTicketWarning())
		//       however, the tickets are currently not reactively bound, but use the sendData mechanism..
		//       this will need to change first
		const f = this.product.fields.ticket?.de?.fields?.ticketOptions?.de?.[0]?.fields
		this.hasTicket = true
			&& f?.reduction?.de !== null
			&& f?.ticketType?.de !== null
			&& f?.price?.de !== null && f?.price?.de !== undefined && f?.price?.de !== ''
		eventBus.$on('has-ticket', (data) => { this.hasTicket = data })

		// repair potentially missing seasonalPricing prop
		if (!this.modelValue) {
			this.$emit('update:modelValue', { de: [] })
		}
	},
	beforeUnmount() {
		eventBus.$off('has-ticket')
	},
}
</script>

<style scoped>
</style>